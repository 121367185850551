<template>
    <div>
        <CreatePicklPopup :OnBoarded="onBoarded" :LoadingBrand="LoadingBrand" :brandId="brandId" popupId="create-pickl-popup" @UpdatePicklList="picklCreated"
        :demographicsFilterFeature="demographicsFilterFeature" :surveyFeatureAvailable="surveyFeatureAvailable" :selfieFeatureAvailable="selfieFeatureAvailable" :multiTaskFeature="multiTaskFeature" :reimbursementFeature="reimbursementFeature" :couponFeature="couponFeature" :localPicklFeature="localPicklFeature"/>
    </div>
</template>

<script>
import CreatePicklPopup from '@/components/common/CreateDoAnywherePicklPage.vue'

export default {
  data () {
    return {
      dashboardContent: {},
      busy: true,
      brandId: this.$route.params.brandId,
      onBoarded: 0,
      brand: {},
      LoadingBrand: true,
      surveyFeatureAvailable: false,
      localPicklFeature: false,
      unlockCodeFeatureAvailable: false,
      selfieFeatureAvailable: false,
      multiTaskFeature: false,
      reimbursementFeature: false,
      couponFeature: false,
      demographicsFilterFeature: false
    }
  },
  name: 'CreatePickl',
  components: {
    CreatePicklPopup
  },
  methods: {
    picklCreated () {
    },
    getBrand () {
      this.$store.dispatch('getBrand', this.$route.params.brandId).then(brand => {
        this.brand = brand
        this.$store.commit('setBrandCredits', this.brand.credits)
        if (this.brand.lock_pickl_feature === 1) {
          this.localPicklFeature = true
        }
        if (this.brand.survey_feature === 1) {
          this.surveyFeatureAvailable = true
        }
        if (this.brand.selfie_feature === 1) {
          this.selfieFeatureAvailable = true
        }
        if (this.brand.multi_pickl_feature === 1) {
          this.multiTaskFeature = true
        }
        if (this.brand.reimbursement_feature === 1) {
          this.reimbursementFeature = true
        }
        if (this.brand.coupon_feature === 1) {
          this.couponFeature = true
        }
        if (this.brand.demographics_filters_feature === 1) {
          this.demographicsFilterFeature = true
        }
        this.onBoarded = this.brand.on_boarded
        this.LoadingBrand = false
      })
    }
  },
  mounted () {
    this.getBrand()
  },

  created () {
    // this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.brand.on_boarded
  }
}
</script>

<style scoped>
h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    margin-top: 0;
}

.emoticon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 29px;
        line-height: 45px;
    }
}
</style>
